import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import Header from "../../open-routes/components/Header";
import Footer from "../../open-routes/components/Footer";

import { serverSizes } from '../../open-routes/components/ServerSizes';

function CreateServer(userData) {
    const user = userData.user;

    useEffect(() => {
        document.title = "Create Cloud Server";
    }, []);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const selectedServerParam = searchParams.get("selectedServer");
    const selectedServer = selectedServerParam ? parseInt(selectedServerParam, 10) : 1;

    const [servers, setServers] = useState([]);
    const [selectedServerIndex, setSelectedServerIndex] = useState(selectedServer);
    const [selectedVolumeSize, setSelectedVolumeSize] = useState(20);
    // const [selectedBandwidth, setSelectedBandwidth] = useState(2);
    const [selectedRegion, setSelectedRegion] = useState("");
    const [selectedOS, setSelectedOS] = useState("");
    const [selectedLinuxVersion, setSelectedLinuxVersion] = useState("");
    const [sshUsername, setSshUsername] = useState("");
    const [sshPassword, setSshPassword] = useState("");
    const [serverName, setServerName] = useState("");
    const [cards, setCards] = useState([]);
    // const [serverConfig, setServerConfig] = useState({});

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState({
        volumeSize: "",
        serverName: "",
        sshUser: "",
        sshPass: "",
        payment: "",
    });

    const [selectedInstance, setSelectedInstance] = useState(serverSizes[selectedServerIndex - 1].value);
    const [selectedServerName, setSelectedServerName] = useState(serverSizes[selectedServerIndex - 1].name);
    const [selectedServerMemory, setSelectedServerMemory] = useState(serverSizes[selectedServerIndex - 1].details.Memory);
    const [selectedServerCore, setSelectedServerCore] = useState(serverSizes[selectedServerIndex - 1].details.Core);
    const [selectedRegionLabel, setSelectedRegionLabel] = useState('');
    
    const [selectedServerHourlyPrice, setSelectedServerHourlyPrice] = useState(serverSizes[selectedServerIndex - 1].price.Hourly);
    const [selectedServerMonthlyPrice, setSelectedServerMonthlyPrice] = useState(serverSizes[selectedServerIndex - 1].price.Monthly);
    const [hourlyPrice, setHourlyPrice] = useState(selectedServerHourlyPrice);
    const [monthlyPrice, setMonthlyPrice] = useState(selectedServerMonthlyPrice);
    const [volumePrice, setVolumePrice] = useState(0);

    const regions = [
        { value: 'us-east-1', label: 'Virginia (US-East)' },
        { value: 'us-east-2', label: 'Ohio (US-East)' },
        { value: 'us-west-1', label: 'N. California (US-West)' },
        { value: 'us-west-2', label: 'Oregon (US-West)' },
        { value: 'af-south-1', label: 'Cape Town (Africa)' },
        { value: 'ap-east-1', label: 'Hong Kong (Asia)' },
        { value: 'ap-south-2', label: 'Hyderabad (Asia)' },
        { value: 'ap-southeast-3', label: 'Jakarta (Asia)' },
        { value: 'ap-southeast-4', label: 'Melbourne (Asia)' },
        { value: 'ap-south-1', label: 'Mumbai (Asia)' },
        { value: 'ap-northeast-3', label: 'Osaka (Asia)' },
        { value: 'ap-northeast-2', label: 'Seoul (Asia)' },
        { value: 'ap-southeast-1', label: 'Singapore (Asia)' },
        { value: 'ap-southeast-2', label: 'Sydney (Asia)' },
        { value: 'ap-northeast-1', label: 'Tokyo (Asia)' },
        { value: 'ca-central-1', label: 'Central (Canada)' },
        { value: 'ca-west-1', label: 'Calgary (Canada-West)' },
        { value: 'eu-central-1', label: 'Frankfurt (Europe)' },
        { value: 'eu-west-1', label: 'Ireland (Europe)' },
        { value: 'eu-west-2', label: 'London (Europe)' },
        { value: 'eu-south-1', label: 'Milan (Europe)' },
        { value: 'eu-west-3', label: 'Paris (Europe)' },
        { value: 'eu-south-2', label: 'Spain (Europe)' },
        { value: 'eu-north-1', label: 'Stockholm (Europe)' },
        { value: 'eu-central-2', label: 'Zurich (Europe)' },
        { value: 'il-central-1', label: 'Tel Aviv (Israel)' },
        { value: 'me-south-1', label: 'Bahrain (Middle East)' },
        { value: 'me-central-1', label: 'UAE (Middle East)' },
        { value: 'sa-east-1', label: 'São Paulo (South America)' },
    ];

    const osOptions = [
        { value: 'Ubuntu', versions: ['24.04', '22.04', '20.04', '18.04'] },
        { value: 'CentOS', versions: ['Stream 9','Stream 8'] },
        { value: 'Amazon Linux', versions: ['Latest'] },
        { value: 'Debian', versions: ['version 12', 'version 11'] },
    ];

    //////////////////////////////////////////////////////////////////////////////////////

    // ACTUAL LIST, UNCOMMENT IN PRODUCTION
    useEffect(() => {
        const fetchServers = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/getServerList`,
                    { poorvaID: user.poorvaID },
                    { withCredentials: true }
                );
                setServers(response.data.collectionsWithInstanceID);
            } catch (error) {
                console.error("Error fetching servers:", error);
            }
        };
        fetchServers();
    }, [user.poorvaID]);

    const handleServerIndex = (e) => {
        setSelectedServerIndex(e.target.value);
        const selectedIndex = e.target.value - 1;
        setSelectedInstance(serverSizes[selectedIndex].value);
        setSelectedServerName(serverSizes[selectedIndex].name);
        setSelectedServerMemory(serverSizes[selectedIndex].details.Memory);
        setSelectedServerCore(serverSizes[selectedIndex].details.Core);
        setSelectedServerHourlyPrice(serverSizes[selectedIndex].price.Hourly);
        setSelectedServerMonthlyPrice(serverSizes[selectedIndex].price.Monthly);

    };

    const handleServerIndex2 = (index) => {
        setSelectedServerIndex(index + 1);
        setSelectedInstance(serverSizes[index].value);
        setSelectedServerName(serverSizes[index].name);
        setSelectedServerMemory(serverSizes[index].details.Memory);
        setSelectedServerCore(serverSizes[index].details.Core);
        setSelectedServerHourlyPrice(serverSizes[index].price.Hourly);
        setSelectedServerMonthlyPrice(serverSizes[index].price.Monthly);

    };
    
    const handlevolumeSizeChange = (e) => {
        const inputValue = parseInt(e.target.value, 10);

        if (inputValue < 20 || inputValue > 2000 || isNaN(inputValue) || e.target.value.trim() === "") {
            setSelectedVolumeSize(inputValue);
            setError((prevError) => ({
                ...prevError,
                serverName: "Only a value between 20 and 2000 GB is allowed.",
            }));
            setVolumePrice(0);
        } else {
            setError((prevError) => ({
                ...prevError,
                serverName: "",
            }));
            setSelectedVolumeSize(inputValue);
            setVolumePrice((inputValue - 20) * 0.00022);
        };

    };

    useEffect(() => {
        const hourlyVolumePrice = volumePrice;
        const monthlyVolumePrice = volumePrice * 24 * 30;
    
        setHourlyPrice(parseFloat((selectedServerHourlyPrice + hourlyVolumePrice).toFixed(4)));
        setMonthlyPrice(parseFloat((selectedServerMonthlyPrice + monthlyVolumePrice).toFixed(4)));
    }, [selectedServerHourlyPrice, selectedServerMonthlyPrice, volumePrice]);

    // const handleBandwidthChange = (e) => {
    //     setSelectedBandwidth(parseInt(e.target.value, 10));
    // };

    const handleRegionChange = (e) => {
        const selectedRegionValue = e.target.value;
        setSelectedRegion(selectedRegionValue);
        const selectedRegionObject = regions.find((region) => region.value === selectedRegionValue);
        if (selectedRegionObject) {
            setSelectedRegionLabel(selectedRegionObject.label);
        } else {
            setSelectedRegionLabel('');
        };
    };

    const handlelinuxDistroChange = (event) => {
        const selectedDistro = event.target.value;
        setSelectedOS(selectedDistro);
    
        // Find the distribution in osOptions
        const distro = osOptions.find((os) => os.value === selectedDistro);
    
        // Set the default version to the first version available for the selected distribution
        if (distro && distro.versions.length > 0) {
            setSelectedLinuxVersion(distro.versions[0]);
        } else {
            setSelectedLinuxVersion(''); // No versions available
        };
    };

    const handlelinuxVersionChange = (event) => {
        setSelectedLinuxVersion(event.target.value);
    };

    const handleSshUser = (e) => {
        const inputValue = e.target.value;

        const isValidUsername = /^[a-zA-Z0-9_]([a-zA-Z0-9_-]{5,14})?$/.test(inputValue);
        setSshUsername(inputValue);

        if (inputValue.length >= 6 && isValidUsername) {
            setError((prevError) => ({
                ...prevError,
                sshUser: "",
            }));
        } else {
            setError((prevError) => ({
                ...prevError,
                sshUser: "Invalid SSH username.",
            }));
        };
    };

    const isStrongPassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=<>?]).{8,}$/;
        return regex.test(password);
    };

    // Flatten the server list to get all server names
    const existingServerNames = Object.values(servers).flat().map(server => server.serverName);

    const handleServerName = (e) => {
        const inputValue = e.target.value;
    
        // Regular expression to match only alphanumeric characters
        const isValid = /^[a-zA-Z0-9]*$/.test(inputValue);
    
        setServerName(inputValue);

        if (existingServerNames.includes(inputValue)) {
            setError((prevError) => ({
                ...prevError,
                serverName: "This server name already exists. Please choose a different name.",
            }));
        } else if (!isValid || inputValue.length < 6 || inputValue.length > 24) {
            setError((prevError) => ({
                ...prevError,
                serverName: "The server name must be between 6 and 24 characters long, containing only letters and numbers.",
            }));
        } else {
            setError((prevError) => ({
                ...prevError,
                serverName: "",
            }));
        }
    };
    

    const handlePassword = (e) => {
        const inputValue = e.target.value;
        setSshPassword(inputValue);

        if (inputValue.length < 8) {
            setError((prevError) => ({
                ...prevError,
                sshPass: "Password should be at least 8 characters long.",
            }));
        } else {
            if (!isStrongPassword(inputValue)) {
                setError((prevError) => ({
                    ...prevError,
                    sshPass: "Password should contain at least 1 uppercase, 1 lowercase, 1 number, and 1 special character.",
                }));
            } else {
                setError((prevError) => ({
                    ...prevError,
                    sshPass: "",
                }));
            }
        };
    };

    useEffect(() => {
        const getCards = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/checkout`,
                    { withCredentials: true }
                );
                const customerID = response.data.customer;
                const res = await axios.post(`${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/secret`,
                    { customer: customerID },
                    { withCredentials: true },
                );
                setCards(res.data.paymentMethods);

            } catch (error) {
                // console.log(error);
            };
        };

        getCards();

    }, []);

    // console.log('CARDS: ', cards.length);

    const serverConfig = {
        userName: user.userName,
        userEmail: user.userName,
        poorvaID: user.poorvaID,
        serverName: serverName,
        instanceType: selectedInstance,
        volumeSize: selectedVolumeSize,
        linuxDistro: selectedOS,
        linuxVersion: selectedLinuxVersion,
        awsRegion: selectedRegion,
        availabilityZone: selectedRegion + 'a',
        sshSftpUser: sshUsername,
        sshSftpPassword: sshPassword,
    };

    const navigate = useNavigate();

    const createServer = () => {
        // setError('');
        setIsLoading(true);

        // if (cards.length === 0) {
        //     setError((prevError) => ({
        //         ...prevError,
        //         payment: "No payment method detected, redirecting to the payment page...",
        //     }));

        //     setTimeout(() => {
        //         navigate('/dashboard/payment');
        //     }, 3000);

        //     return;
        // };

        setTimeout(() => {
            setIsLoading(false);
            navigate('/dashboard/products', { state: { serverConfig } });
        }, 1000);
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    // console.log("Username: ", user.userName);
    // console.log("Server Name: ", serverName);
    // console.log("Customer ID: ", user.poorvaID);
    // console.log("Instance Type: ",selectedInstance);
    // console.log("Volume: ",selectedVolumeSize);
    // console.log("OS: ",selectedOS);
    // console.log("OS Version: ",selectedLinuxVersion);
    // console.log("Region: ",selectedRegion);
    // console.log("Availability Zone: ", selectedRegion + 'a');
    // console.log("SSH User: ",sshUsername);

    return (
        <>
            <Header userData={user}/>

            <div className="bg-white text-black px-5 py-20 md:px-20 md:py-32 text-center">
                <h1 className="md:text-[90px] text-[50px] font-bold leading-tight tracking-tighter mb-5">Craft Your <span className='text-custom-green'>Dream Server</span></h1>
                <p>Tailor-Made Servers, Built to Your Specifications.</p>
            </div>

            <div className="w-full overflow-x-auto">
                <div className="w-[300%] md:w-[130%] lg:w-full p-5 md:p-20 text-center bg-white text-black flex justify-center">
                    <div className="w-full p-10 flex flex-col items-center gap-12 shadow-custom3 rounded-2xl">

                        <div className="w-full flex flex-col gap-5 items-center">
                            <h1 className="w-full -mb-3 text-lg font-bold text-left uppercase text-custom-green">Server Size</h1>
                            <p className="w-full -mt-2 text-sm font-light text-left">Select your server size. You can always scale your server size whenever required.</p>

                            <input
                                type="range"
                                min="1"
                                max={serverSizes.length}
                                step="1"
                                list="tickmarks"
                                value={selectedServerIndex}
                                onChange={handleServerIndex}                                
                                className="w-full accent-custom-green"
                            />
                            <datalist id="tickmarks">
                                {Array.from({ length: serverSizes.length }).map((_, index) => (
                                    <option key={index} value={index + 1}></option>
                                ))}
                            </datalist>

                            <div className="w-[104%] flex justify-between -mt-4">
                                {serverSizes.map((server, index) => (
                                    <span
                                        key={index}
                                        className="flex-1 text-xs cursor-pointer"
                                        title={`PC ${server.name} AWS\n${server.details.Memory}, ${server.details.Core}`}
                                        onClick={() => handleServerIndex2(index)}
                                    >{server.name}
                                    </span>
                                ))}
                            </div>

                            <div className='w-full'>
                                <p className="text-left font-light text-sm"><Link to="/plans" className="hover:font-normal underline">Click here</Link> to check the specifications of all the servers.</p>
                            </div>

                        </div>

                        <div className="w-full flex flex-col gap-5 items-center">
                            <h1 className="w-full -mb-3 text-lg font-bold text-left uppercase text-custom-green">Storage</h1>
                            <p className="w-full -mt-2 text-sm font-light text-left">Select your storage size. You can always scale up your storage size after launch.</p>
                            <div className="w-full flex gap-5 items-center">
                                <div className='w-[90%]'>
                                    <input
                                        // onFocus={(e) => e.target.blur()}
                                        type="range"
                                        min="20"
                                        max="2000"
                                        step="10"
                                        value={selectedVolumeSize}
                                        onChange={handlevolumeSizeChange}
                                        className="w-full accent-custom-green"
                                    />

                                    <div className="w-full flex justify-between">
                                        <span className="flex-1 text-xs text-left">20 GB</span>
                                        <span className="flex-1 text-xs text-right">2000 GB</span>
                                    </div>
                                </div>

                                <div className="w-[15%] lg:w-[10%] flex items-center gap-2">
                                    <input
                                        onFocus={(e) => e.target.blur()}
                                        type="number"
                                        min="20"
                                        max="2000"
                                        step="10"
                                        value={selectedVolumeSize}
                                        onChange={handlevolumeSizeChange}
                                        className="w-[100%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                    />
                                    <p>GB</p>
                                </div>
                            </div>
                            {error.volumeSize && (
                                <div className='w-full -mt-4'>
                                    <p className='text-red-700 text-xs text-right'>{error.volumeSize}</p>
                                </div>
                            )}
                        </div>

                        {/* <div className="w-full flex flex-col gap-5 items-center">
                            <h1 className="w-full -mb-3 text-lg font-bold text-left uppercase">Bandwidth</h1>
                            <p className="w-full -mt-2 text-sm font-light text-left">Bandwidth is on pay-as-you-go basis. You can select your expected bandwidth consumption per<br />month to get your estimated monthly cost.</p>
                            <div className="w-full flex gap-5 items-center">
                                <div className='w-[90%]'>
                                    <input
                                        type="range"
                                        min="2"
                                        max="500"
                                        step="1"
                                        value={selectedBandwidth}
                                        onChange={handleBandwidthChange}
                                        className="w-full accent-color1"
                                    />

                                    <div className="w-full flex justify-between">
                                        <span className="flex-1 text-xs text-left">{selectedBandwidth} GB</span>
                                        <span className="flex-1 text-xs text-right">500 GB</span>
                                    </div>
                                </div>

                                <div className="w-[10%] flex items-center gap-2">
                                    <input
                                        type="number"
                                        min="2"
                                        max="500"
                                        step="1"
                                        value={selectedBandwidth}
                                        onChange={handleBandwidthChange}
                                        className="w-full placeholder:italic placeholder:opacity-50 placeholder:text-color3 bg-transparent border border-color3 rounded-md py-2 px-3 focus:outline-none focus:border-color3 focus:ring-color3 focus:ring-1 sm:text-sm"
                                    />
                                    <p>GB</p>
                                </div>
                            </div>
                        </div> */}

                        <div className="w-full flex flex-col gap-5 items-start">
                            <label className="w-full text-lg font-bold text-left uppercase text-custom-green">Region</label>
                            <p className="w-full -mt-5 text-sm font-light text-left">Select your server location.</p>
                            <select
                                value={selectedRegion}
                                onChange={handleRegionChange}
                                className="w-[20%] -mt-2 border-b border-gray-400 p-1 bg-transparent"
                            >
                                <option value="">-- Select Region --</option>
                                {regions.map((region) => (
                                    <option key={region.value} value={region.value}>
                                        {region.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {selectedRegion && (
                            <div className="w-full">
                                <div className="flex flex-col gap-5 items-start">
                                    <label className="w-full text-lg font-bold text-left uppercase text-custom-green">
                                        Select OS (Linux Distro)
                                    </label>
                                    <p className="w-full -mt-5 text-sm font-light text-left">Select the Linux OS according to your need.</p>
                                    <select
                                        id="options"
                                        name="options"
                                        value={selectedOS}
                                        onChange={handlelinuxDistroChange}
                                        className="w-[20%] -mt-2 p-1 border-b border-gray-400 bg-transparent"
                                    >
                                        <option value="">-- Select OS --</option>
                                        {osOptions.map((os) => (
                                            <option key={os.value} value={os.value}>
                                                {os.value}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {selectedOS && (
                                    <div className="mt-8">
                                        {/* Additional content based on selectedOS */}
                                        {osOptions
                                            .filter((os) => os.value === selectedOS)
                                            .map((os) => (
                                                <div key={os.value} className="flex flex-col gap-5 items-start">
                                                    <label htmlFor="versions" className="w-full -mb-3 text-sm font-bold text-left uppercase text-custom-green">
                                                        Choose {os.value} Version
                                                    </label>
                                                    <p className="-mt-2 text-left text-sm font-light">
                                                        Next, specify the version of your chosen OS. Selecting the right version ensures compatibility and optimal performance for your specific use case.
                                                    </p>
                                                    <select
                                                        id="versions"
                                                        name="versions"
                                                        value={selectedLinuxVersion}
                                                        onChange={handlelinuxVersionChange}
                                                        className="w-[20%] p-1 border-b border-gray-400 bg-transparent"
                                                    >
                                                        {os.versions.map((version) => (
                                                            <option key={version} value={version}>
                                                                {version}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    <div className="w-full mt-6 flex flex-col gap-5 items-start">
                                                        <h1 className="w-full -mb-3 text-sm font-bold text-left uppercase text-custom-green">
                                                            Server Name
                                                        </h1>
                                                        <p className="-mt-2 text-left text-sm">
                                                            Server name is the name of your instance in 6-24 characters for the identification only.
                                                        </p>
                                                        <input
                                                            type="text"
                                                            value={serverName}
                                                            onChange={handleServerName}
                                                            placeholder='Server Name'
                                                            className="w-[25%] lg:w-[20%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                                        />
                                                        {error.serverName && (
                                                            <div className='w-full -mt-2'>
                                                                <p className='text-red-700 text-xs text-left font-bold'>{error.serverName}</p>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="w-full mt-6 flex flex-col gap-5 items-start">
                                                        <h1 className="w-full -mb-3 text-sm font-bold text-left uppercase text-custom-green">
                                                            SSH/SFTP User
                                                        </h1>
                                                        <p className="-mt-2 text-left text-sm font-light">
                                                            Type a specific username to access the server through SSH/SFTP or a default username will be passed.<br/>
                                                            All the details will be sent to your registered email address and saved to your dashboard as well.
                                                        </p>
                                                        <p className="-mt-2 text-left text-sm">
                                                            The SSH username must be 6-15 characters, start with an alphanumeric character,<br/>
                                                            and can only contain alphanumeric characters, underscores, and dashes.
                                                        </p>
                                                        <input
                                                            type="text"
                                                            value={sshUsername}
                                                            onChange={handleSshUser}
                                                            placeholder='SSH User'
                                                            className="w-[25%] lg:w-[20%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                                        />
                                                        {error.sshUser && (
                                                            <div className='w-full -mt-2'>
                                                                <p className='text-red-700 text-xs text-left font-bold'>{error.sshUser}</p>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="w-full mt-6 flex flex-col gap-5 items-start">
                                                        <h1 className="w-full -mb-3 text-sm font-bold text-left uppercase text-custom-green">
                                                            SSH/SFTP Password
                                                        </h1>
                                                        <p className="-mt-2 text-left text-sm">
                                                            The SSH password must be at least 8 characters long, and contains at least 1 uppercase,<br/>
                                                            1 lowercase, 1 number and 1 special character.
                                                        </p>
                                                        <div className='w-[25%] lg:w-[20%] flex justify-start items-center'>
                                                            <input
                                                                type={passwordVisible ? "text" : "password"}
                                                                value={sshPassword}
                                                                onChange={handlePassword}
                                                                placeholder='SSH Password'
                                                                className="w-full pr-10 placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                                            />
                                                            <i onClick={togglePasswordVisibility} className={`-ml-7 z-30 cursor-pointer fa-solid text-white ${passwordVisible ? "fa-eye-slash" : "fa-eye"}`}></i>
                                                        </div>
                                                        {error.sshPass && (
                                                            <div className='w-full -mt-2'>
                                                                <p className='text-red-700 text-xs text-left font-bold'>{error.sshPass}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                )}
                            </div>
                        )}

                        <p className="w-full text-sm text-left font-bold">Creating server might take up to 5 minutes.</p>
                    </div>
                </div>

            </div>
            
            <div className="sticky bottom-0 z-30 glass-green w-full px-5 lg:px-20 py-5 flex flex-col md:gap-0 gap-4">
                <div className="w-full flex md:justify-between flex-col md:flex-row md:items-center md:gap-0 gap-4 text-white">
                    
                    <div className='flex-1'>
                        <h2 className='font-bold'>PC {selectedServerName} AWS,
                            {selectedRegionLabel && (
                                <span className='text-sm font-normal'> {selectedRegionLabel}</span>
                            )}
                        </h2>
                        <div className='flex flex-col justify-center items-start'>
                            <div className='flex justify-center items-start md:items-center gap-1 text-sm font-light'>
                                <p>{selectedServerMemory}, {selectedServerCore},</p>
                                <p><span className='font-bold'>Storage: </span>{selectedVolumeSize} GB</p>
                            </div>
                            {selectedOS && (
                                <div className='text-sm font-light flex flex-col'>
                                    <div className='flex'>
                                        <p><span className='font-bold'>OS: </span>{selectedOS} {selectedLinuxVersion}</p>
                                        {serverName && (
                                            <p>, <span className='font-bold'>Server Name: </span>{serverName}</p>
                                        )}
                                    </div>
                                    {sshUsername && (
                                        <div className='text-sm font-light'>
                                            <p><span className='font-bold'>SSH User: </span>{sshUsername}</p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='flex-1 flex flex-col lg:items-start items-start'>
                        <h2 className='font-bold'>Pay as you go: </h2>
                        <div className='flex flex-col lg:flex-row lg:gap-1'>
                            <p>$<span className='text-2xl font-bold'>{hourlyPrice}</span>/hour or</p>
                            <p>$<span className='text-2xl font-bold'>{monthlyPrice}</span>/month</p>
                        </div>
                    </div>
                    
                    <button
                        disabled={isLoading || !selectedOS || !selectedRegion || !sshUsername || !sshPassword || !serverName || Object.values(error).some(errorMessage => errorMessage)}
                        onClick={createServer}
                        className={`${isLoading || !selectedOS || !selectedRegion || !sshUsername || !sshPassword || !serverName || Object.values(error).some(errorMessage => errorMessage)} ? 'cursor-not-allowed opacity-70' : ''} w-[60%] md:w-[25%] lg:w-[20%] elastic-button2`}
                    >
                        {isLoading ?
                            <div className="flex items-center gap-1">
                                <div className='spinner2'></div>
                                Redirecting...
                            </div>
                        :
                            "Create Server"
                        }
                    </button>

                </div>

                {error.payment && (
                    <div className='w-full mt-4'>
                        <p className='text-red-700 text-sm text-center'>{error.payment}</p>
                    </div>
                )}

                {/* {error && error.includes("Error creating the server") && (
                    <div className='w-full mt-4'>
                        <p className='text-red-700 text-sm text-center'>{error}</p>
                    </div>
                )}    */}
            </div>

            <Footer />
        </>
    )

}

export default CreateServer;
