import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';

function AppDetails(userData) {
    const user = userData.userData;
    const server = userData.server;
    const collectionName = userData.collection;
    const application = userData.appDetails;

    useEffect(() => {
        document.title = `Manage your application - ${application.applicationName}`;
    }, [application.applicationName]);

    const navigate = useNavigate();

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isPasswordVisible2, setIsPasswordVisible2] = useState(false);
    const [isPasswordVisible3, setIsPasswordVisible3] = useState(false);
    const [copyLocation, setCopyLocation] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [dialogError, setDialogError] = useState("");
    const [disableDeleteButton, setDisableDeleteButton] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [appName, setAppName] = useState("");

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(prevState => !prevState);
    }

    const togglePasswordVisibility2 = () => {
        setIsPasswordVisible2(prevState => !prevState);
    }

    const togglePasswordVisibility3 = () => {
        setIsPasswordVisible3(prevState => !prevState);
    }

    const handleCopy = (location, textToCopy) => {
        if (window.isSecureContext && navigator.clipboard) {
            // Secure context (HTTPS)
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    setCopyLocation(location);
                    setTimeout(() => setCopyLocation(""), 2000);
                })
                .catch(err => {
                    console.error('Failed to copy in secure context: ', err);
                });
        } else {
            // Insecure context (HTTP) - Fallback method
            try {
                const textArea = document.createElement("textarea");
                textArea.value = textToCopy;
                textArea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
                textArea.style.opacity = "0";  // Hide the textarea element
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                document.execCommand('copy');
                document.body.removeChild(textArea);

                setCopyLocation(location);
                setTimeout(() => setCopyLocation(""), 2000);
            } catch (err) {
                console.error('Failed to copy in insecure context: ', err);
            }
        }
    };

    const deleteApp = async () => {
        setDisableDeleteButton(true);

        if (appName !== application.applicationName) {
            setDialogError("Application name did not match!");
            setTimeout(() => setDialogError(""), 5000);
            setDisableDeleteButton(false);
            return;
        };

        if (!application.tempDomain) {
            try {
                await axios.post(`${process.env.REACT_APP_APPLICATION_MANAGEMENT_API}/api/deleteApplication`,
                    {
                        applicationName: application.applicationName,
                        serverName: server.serverName,
                        userName: user.userName,
                        poorvaID: user.poorvaID
                    },
                    { withCredentials: true }
                );

                setSuccessMessage(`Application ${application.applicationName} deleted successfully, redirecting..`);
                setTimeout(() => {
                    navigate(`/dashboard/manageserver/${collectionName}?createApp`);
                }, 2000);

            } catch (error) {
                setErrorMessage("An error occurred while deleting the application.");
                setTimeout(() => setErrorMessage(""), 5000);
            };
        };

        if (application.tempDomain) {
            try {
                await axios.post(`${process.env.REACT_APP_DNS_SSL_MANAGEMENT_API}/auth/deleteDnsRecord`,
                    { applicationName: application.applicationName },
                    { withCredentials: true }
                );

                try {
                    await axios.post(`${process.env.REACT_APP_APPLICATION_MANAGEMENT_API}/api/deleteApplication`,
                        {
                            applicationName: application.applicationName,
                            serverName: server.serverName,
                            userName: user.userName,
                            poorvaID: user.poorvaID
                        },
                        { withCredentials: true }
                    );

                    setSuccessMessage(`Application ${application.applicationName} deleted successfully, redirecting..`);
                    setTimeout(() => {
                        navigate(`/dashboard/manageserver/${collectionName}?createApp`);
                    }, 2000);

                } catch (error) {
                    setErrorMessage("An error occurred while deleting the application.");
                    setTimeout(() => setErrorMessage(""), 5000);
                };

            } catch (error) {
                setErrorMessage("Application not deleted- An error occurred while removing the DNS records.");
                setTimeout(() => setErrorMessage(""), 5000);
            };
        }

        setDisableDeleteButton(false);
    };

    return (
        <>
            <div className="flex mb-10 gap-2 items-center text-xs font-bold flex-wrap">
                <Link to="/dashboard" className="cursor-pointer hover:text-custom-green">
                    Dashboard
                </Link>
                <i className="fa-solid fa-chevron-right"></i>
                <Link to="/dashboard/products" className="cursor-pointer hover:text-custom-green">
                    Products
                </Link>
                <i className=" fa-solid fa-chevron-right"></i>
                <Link to={`/dashboard/manageserver/${collectionName}?createApp`} className="cursor-pointer hover:text-custom-green">
                    Application Manager
                </Link>
                <i className="fa-solid fa-chevron-right"></i>
                <p className="text-custom-green">
                    Application Details
                </p>
            </div>

            <div>
                <h1 className="text-xl font-bold">DOMAIN:{" "}
                    <a
                        href={`http://${application.applicationName}`}
                        className="text-custom-green"
                        target="_blank" // Opens the link in a new tab.
                        rel="noopener noreferrer" // Improves security and performance when opening links in a new tab by preventing access to the window.opener object (recommended practice for external links)
                    >
                        {application.applicationName}{" "}<i className="text-black fa-solid fa-up-right-from-square"></i>
                    </a>
                </h1>
                <p className="font-light">This interface lets you manage your application with various options.</p>
            </div>

            <div className="flex lg:flex-row flex-col mt-10">
                <div className="flex flex-col lg:flex-1 p-5">
                    <h1 className="text-md font-bold uppercase mb-2">Basic Details</h1>
                    <table className="text-sm">
                        <tbody>
                            <tr>
                                <td>Software:</td>
                                <td className="capitalize">{application.applicationType === "wordpress" ?
                                    (
                                        "WordPress"
                                    ) : (
                                        application.applicationType
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>Document Root:</td>
                                <td>/applications/{application.applicationName}/httpdocs</td>
                            </tr>
                            {/* <tr>
                                <td>Container Port:</td>
                                <td>{application.applicationPort}</td>
                            </tr> */}
                        </tbody>
                    </table>

                    {application.applicationType !== "basic" &&
                        <div className="w-full mt-8 md:w-[90%]">
                            <h1 className="text-md font-bold uppercase mb-2">Backend Admin Details</h1>
                            <table className="text-sm">
                                <tbody>
                                    <tr>
                                        <td className="w-[30%]">Login URL:</td>
                                        <td>
                                            <a
                                                href={`http://${application.applicationName}/wp-admin/`}
                                                className="text-custom-green"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Click here{" "}<i className="text-black fa-solid fa-up-right-from-square"></i>
                                            </a>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Username:</td>
                                        <td>{application.adminUser}</td>
                                        <td><i className="fa-regular fa-copy cursor-pointer" title="copy" onClick={() => handleCopy("adminpassword", application.adminUser)}></i></td>
                                    </tr>
                                    <tr>
                                        <td>Password:</td>
                                        <td>
                                            <input
                                                className="justify-center items-center bg-transparent w-[70%]"
                                                type={isPasswordVisible3 ? "text" : "password"}
                                                value={isPasswordVisible3 ? `${application.adminPassword}` : "*****************"}
                                                disabled
                                            />
                                        </td>
                                        <td className="flex justify-start items-center">
                                            <i className="fa-regular fa-copy cursor-pointer" title="copy" onClick={() => handleCopy("adminpassword", application.adminPassword)}></i>
                                            <i onClick={togglePasswordVisibility3} className={isPasswordVisible3 ? "cursor-pointer fa-solid fa-eye-slash ml-4" : "cursor-pointer fa-solid fa-eye ml-4"}></i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {copyLocation === "adminpassword" && <div className="text-custom-green text-sm">Copied!</div>}
                        </div>
                    }

                    <div className="flex flex-col mt-8">
                        <p className="text-md font-bold uppercase mb-2">Current PHP Version</p>
                        <select className="md:w-[50%] border-2 border-custom-green bg-black text-white rounded-md px-1 py-[5.5px]">
                            <option value="php74">PHP 7.4</option>
                            <option value="php80">PHP 8.0</option>
                            <option value="php81">PHP 8.1</option>
                            <option value="php82">PHP 8.2</option>
                        </select>
                        <div className="flex text-sm">
                            <button className="mt-2 button1" type="submit">Change</button>
                        </div>
                    </div>

                    <h1 className="text-md font-bold uppercase mt-8 mb-2">Database Details</h1>
                    <div className="md:w-[90%]">
                        <table className="text-sm">
                            <tbody>
                                <tr>
                                    <td className="w-[40%]">Database Name:</td>
                                    <td>{application.applicationDbName}</td>
                                    <td><i className="fa-regular fa-copy cursor-pointer" title="copy" onClick={() => handleCopy("dbpassword", application.applicationDbName)}></i></td>
                                </tr>
                                <tr>
                                    <td>User:</td>
                                    <td>{application.applicationDbUser}</td>
                                    <td><i className="fa-regular fa-copy cursor-pointer" title="copy" onClick={() => handleCopy("dbpassword", application.applicationDbUser)}></i></td>
                                </tr>
                                <tr>
                                    <td>Password:</td>
                                    <td>
                                        <input className="w-[60%] justify-center items-center bg-transparent" type={isPasswordVisible ? "text" : "password"} value={isPasswordVisible ? `${application.applicationDbUserPassword}` : "*****************"} id="password" disabled />
                                    </td>
                                    <td className="flex justify-start items-center">
                                        <i className="fa-regular fa-copy cursor-pointer" title="copy" onClick={() => handleCopy("dbpassword", application.applicationDbUserPassword)}></i>
                                        <i onClick={togglePasswordVisibility} className={isPasswordVisible ? "cursor-pointer fa-solid fa-eye-slash ml-4" : "cursor-pointer fa-solid fa-eye ml-4"}></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {copyLocation === "dbpassword" && <div className="text-custom-green text-sm">Copied!</div>}

                </div>
                <div className="flex flex-col lg:flex-1 p-5">

                    <h1 className="text-md font-bold uppercase mb-2">Change Database User's Password</h1>
                    <div className="text-sm flex flex-col">
                        <div className=" items-center">
                            <input className="w-[80%] placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm" placeholder="New password" type={isPasswordVisible2 ? "text" : "password"} id="changePassword" />
                            <i onClick={togglePasswordVisibility2} className={isPasswordVisible2 ? "cursor-pointer fa-solid fa-eye-slash ml-4" : "cursor-pointer fa-solid fa-eye ml-4"}></i>
                        </div>
                        <div className="flex mt-2">
                            <button className="button1" type="submit">Change</button>
                        </div>
                    </div>

                    <h1 className="text-md font-bold uppercase mt-8">Access Database:</h1>
                    <p className="text-sm">A graphical interface to manage your database.</p>
                    <div className="flex justify-between mt-2">
                        <button
                            className="elastic-button1 w-44"
                            title="Launch"
                        >
                            phpMyAdmin
                        </button>
                    </div>

                    <h1 className="text-md font-bold uppercase mt-8">Container Service:</h1>
                    <p className="text-sm">The container service for your domain, if it's powered off the website will go down.</p>
                    <div className="flex justify-between mt-2 gap-2">
                        <button className="text-center border-2 border-emerald-900 bg-transparent text-emerald-900 rounded-[6px] w-[33%] py-1 hover:bg-emerald-900 hover:text-white transition duration-150 ease-in-out" type="submit" title="Start"><i className="fa-solid fa-play"></i></button>
                        <button className="text-center border-2 border-red-700 bg-transparent text-red-700 rounded-[6px] w-[33%] py-1 hover:bg-red-700 hover:text-white transition duration-150 ease-in-out" type="submit" title="Stop"><i className="fa-solid fa-power-off"></i></button>
                        <button className="text-center border-2 border-amber-500 bg-transparent text-amber-500 rounded-[6px] w-[33%] py-1 hover:bg-amber-500 hover:text-white transition duration-150 ease-in-out" type="submit" title="Restart"><i className="fa-solid fa-rotate-right"></i></button>
                    </div>

                    <div className="mt-8 flex gap-2 flex-col">
                        <div>
                            <h1 className="font-bold uppercase">Delete Website with its Data:</h1>
                            <p className="text-sm">WARNING: You will lose all the data associated with this domain, this action can't be reverted.</p>
                        </div>
                        <button
                            className="sm:w-[60%] button-red"
                            type="submit"
                            onClick={() => setOpenDeleteDialog(true)}
                            disabled={openDeleteDialog}
                        >
                            Delete Website
                        </button>
                    </div>

                    {openDeleteDialog &&
                        <div className="mt-4 flex flex-col items-center justify-center text-left p-2 font-light text-sm border border-black border-opacity-50 rounded-lg">
                            <p className="text-left">
                                <span className="font-bold text-red-700">WARNING: </span>
                                This will delete the entire website with it's files and database if it has any.
                            </p>
                            <p className="mt-2 mb-1">To proceed, type the application name (<span className="italic text-custom-green font-normal">{application.applicationName}</span>) in the required field.</p>
                            <input
                                className="w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                type="text"
                                name="appName"
                                required
                                value={appName}
                                onChange={(e) => setAppName(e.target.value)}
                            />

                            {dialogError &&
                                <div className="text-red-700 text-xs font-bold mt-1 text-left flex w-full">{dialogError}</div>
                            }

                            <div className="w-full mt-2 flex justify-end items-center gap-2">
                                <button
                                    type="submit"
                                    onClick={() => {
                                        setOpenDeleteDialog(false);
                                        setAppName("");
                                    }
                                    }
                                    disabled={disableDeleteButton}
                                    className="button-transparent border-2 border-black rounded-md"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="button-red border-2 border-red-700"
                                    onClick={deleteApp}
                                    disabled={disableDeleteButton}
                                >
                                    {disableDeleteButton ? <div className="spinner-sm"></div> : "Delete"}
                                </button>
                            </div>
                        </div>
                    }

                    <div className="mt-8">
                        {errorMessage &&
                            <div className="text-red-700 text-xs font-bold">{errorMessage}</div>
                        }
                        {successMessage &&
                            <div className="text-green-700 text-xs font-bold">{successMessage}</div>
                        }
                    </div>
                </div>

            </div>
        </>
    )
}

export default AppDetails;
