// Import necessary dependencies and components
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';

import Loading from "./components/Loading";
import ScrollToTop from "./components/ScrollToTop";

// IMPORT PUBLIC ROUTES
import Home from "./pages/open-routes/web-pages/home/Home";
import Support from "./pages/open-routes/web-pages/Support";
import Features from "./pages/open-routes/web-pages/Features";
import Privacy from "./pages/open-routes/web-pages/Privacy";
import TermsAndConditions from "./pages/open-routes/web-pages/Terms";
import About from "./pages/open-routes/web-pages/About";
import Contact from "./pages/open-routes/web-pages/Contact";
import Signin from "./pages/open-routes/web-pages/Signin";
import Signup from "./pages/open-routes/web-pages/Signup";
import ResetPassword from "./pages/open-routes/web-pages/ResetPassword";
import Plans from "./pages/open-routes/web-pages/Plans";
import Checkout from "./pages/open-routes/web-pages/Checkout";
import NotFound from "./pages/open-routes/web-pages/404NotFound";

// IMPORT PRIVATE ROUTES
// DASHBOARD
import Dashboard from "./pages/private-routes/dashboard/Dashboard";
import Products from "./pages/private-routes/dashboard/Products";
import Invoices from "./pages/private-routes/dashboard/Invoices";
// import InvoiceDetails from "./pages/private-routes/dashboard/InvoiceDetails";
import ContactSupport from "./pages/private-routes/dashboard/OpenTicket";
import MyTickets from "./pages/private-routes/dashboard/Tickets";

// PAYMENT
import Payment from "./pages/private-routes/payment/stripe/Payment";
// import Payment from "./pages/private-routes/payment/payu/Payment";
import PaymentSuccess from "./pages/private-routes/payment/Success";
import PaymentFailed from "./pages/private-routes/payment/Failed";

// SERVER-AND-APP
import ManageServer from "./pages/private-routes/server-and-app/ManageServer";
import ManageApps from "./pages/private-routes/server-and-app/ManageApps";

// USER ACCOUNT
import UserProfile from "./pages/private-routes/user-account/Profile";
import UserSettings from "./pages/private-routes/user-account/Settings";
import ChangePassword from "./pages/private-routes/user-account/ChangePassword";

// CREATE SERVER
import CreateServer from "./pages/private-routes/create-server/CreateServer";

function App() {

	const [user, setUser] = useState(null);
	const [isLoading, setIsLoading] = useState(true);


	const getUser = async () => {
		try {
			const url = `${process.env.REACT_APP_PAYMENT_AUTHENTICATION_MAIL_API}/auth/login/success`;
			const response = await axios.get(url, { withCredentials: true });

			setUser(response.data.user);
			// console.log(response.data.user);
			// console.log("RESPONSE: ", response.data);
		} catch (error) {
			// console.error("Error authenticating user: ", error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		// Call getUser immediately when the component mounts
		getUser();
	
		// Set up the interval to call getUser every 5 seconds
		const intervalId = setInterval(() => {
		  getUser();
		}, 5000);
	
		// Clear the interval when the component unmounts
		return () => clearInterval(intervalId);
	}, []);

	// console.log("USER: ", user);

	useEffect(() => {
		if (!user) {
			// Only set loading to false if the user data hasn't been fetched successfully
			const redirectTimer = setTimeout(() => {
				setIsLoading(false);
			}, 2000); // 2 seconds delay after determining no user is fetched
	
			return () => clearTimeout(redirectTimer);
		} else {
			// If user data is available, immediately stop loading
			setIsLoading(false);
		}
	}, [user]);

	if (!user && isLoading) {
		return <Loading />;
	}

	return (

		<div className="App">
			<Router>
				<ScrollToTop />
				<Routes>
					{/* <Route path="/load" element={<Loading />} /> */}
					{/* **************** PUBLIC ROUTES **************** */}
					<Route exact path="/" element={<Home user={user} />} />
					<Route path="/support" element={<Support user={user} />} />
					<Route path="/features" element={<Features user={user} />} />
					<Route path="/privacy" element={<Privacy user={user} />} />
					<Route path="/terms" element={<TermsAndConditions user={user} />} />
					<Route path="/about" element={<About user={user} />} />
					<Route path="/contact" element={<Contact user={user} />} />
					<Route path="/plans" element={<Plans user={user} />} />
					<Route path="/signin" element={<Signin user={user} />} />
					<Route path="/signup" element={<Signup user={user} />} />
					<Route path="/resetpassword" element={<ResetPassword user={user} />} />
					<Route path="/checkout" element={<Checkout user={user} />} />
					<Route path="/dashboard/payment/success" element={<PaymentSuccess/>} />
					<Route path="/dashboard/payment/failed" element={<PaymentFailed/>} />
					<Route path="*" element={<NotFound user={user} />} />

					{/* **************** PRIVATE ROUTES **************** */}
					{/* DASHBOARD PAGES */}
					<Route exact path="/dashboard" element={user ? <Dashboard user={user} /> : <Navigate to="/signin" /> } />
					<Route path="/dashboard/products" element={user ? <Products user={user} /> : <Navigate to="/signin" /> } />
					<Route path="/dashboard/invoices" element={user ? <Invoices user={user} /> : <Navigate to="/signin" /> } />
					{/* <Route path="/dashboard/invoicedetails/:invoiceID" element={user ? <InvoiceDetails user={user} /> : <Navigate to="/signin" /> } /> */}
					<Route path="/dashboard/payment" element={user ? <Payment user={user} /> : <Navigate to="/signin" /> } />
					<Route path="/dashboard/contactsupport" element={user ? <ContactSupport user={user} /> : <Navigate to="/signin" /> } />
					<Route path="/dashboard/mytickets" element={user ? <MyTickets user={user} /> : <Navigate to="/signin" /> } />
					
					{/* SERVER AND APPS */}
					<Route path="/dashboard/manageserver/:collectionName" element={user ? <ManageServer user={user} /> : <Navigate to="/signin" /> } />
					<Route path="/dashboard/manageserver/:collectionName/app/:appName" element={user ? <ManageApps user={user} /> : <Navigate to="/signin" /> } />

					{/* USER PAGES */}
					<Route path="/user/profile" element={user ? <UserProfile user={user} /> : <Navigate to="/signin" /> } />
					<Route path="/user/settings" element={user ? <UserSettings user={user} /> : <Navigate to="/signin" /> } />
					<Route path="/user/changepassword" element={user ? <ChangePassword user={user} /> : <Navigate to="/signin" /> } />
					
					{/* CREATE SERVER */}
					<Route path="/createserver" element={user ? <CreateServer user={user} /> : <Navigate to="/signin" />} />

				</Routes>
			</Router>
		</div>
	);
}

export default App;
