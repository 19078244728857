import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';

function CreateApp(userData) {
    const user = userData.userData;
    const collectionName = userData.collection;
    const server = userData.server;

    // Filter out the apps that has "removed" status
    const applications = server.applications.filter(app => app.applicationStatus !== 'removed');
    
    const appBeingCreated = applications.find(app => app.applicationStatus === 'creating');
    const appBeingDeleted = applications.find(app => app.applicationStatus === 'deleting');
    // console.log("AAAAA: ", appBeingCreated?.applicationName)
    // console.log(appBeingCreated?.applicationStatus);
    // console.log("SERSERSER: ", server.applications);

    useEffect(() => {
        document.title = "Manage your application";
    }, []);

    const [showDomainInput, setShowDomainInput] = useState(false);
    const [tempDomain, setTempDomain] = useState(false);
    const [needDatabase, setNeedDatabase] = useState(false);
    const [domainName, setDomainName] = useState("");
    const [appType, setAppType] = useState("");
    const [dbName, setDbName] = useState("");
    const [dbUser, setDbUser] = useState("");
    const [dbPassword, setDbPassword] = useState("");
    const [adminUser, setAdminUser] = useState("");
    const [adminPassword, setAdminPassword] = useState("");
    const [error, setError] = useState("");
    const [dnsError, setDNSError] = useState("");
    const [domainError, setDomainError] = useState("");
    const [appError, setAppError] = useState("");
    const [dbPasswordVisible, setDbPasswordVisible] = useState(false);
    const [adminPasswordVisible, setAdminPasswordVisible] = useState(false);
    const [dbNameError, setDbNameError] = useState("");
    const [dbUserError, setDbUserError] = useState("");
    const [dbPasswordError, setDbPasswordError] = useState("");
    const [adminUserError, setAdminUserError] = useState("");
    const [adminPasswordError, setAdminPasswordError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    // const [containerPort, setContainerPort] = useState(null);
    const [phpVersion, setPhpVersion] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [creatingApp, setCreatingApp] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [step, setStep] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    // const [loading, setLoading] = useState(false);
    // const [isDropdownOpen, setDropdownOpen] = useState(false);

    const [successDeleteMessage, setSuccessDeleteMessage] = useState("");
    const [errorDeleteMessage, setErrorDeleteMessage] = useState("");
    const [dialogError, setDialogError] = useState("");
    const [disableDeleteButton, setDisableDeleteButton] = useState("");
    const [openDeleteDialog, setOpenDeleteDialog] = useState("");
    const [appName, setAppName] = useState("");
    const [deletingApp, setDeletingApp] = useState("");
    const [deleteProgress, setDeleteProgress] = useState(0);
    const [createProgress, setCreateProgress] = useState(0);
    // const [creatingDomain, setCreatingDomain] = useState("");
    // const [creatingAppType, setCreatingApptype] = useState("");


    const handleCreateApplicationClick = () => {
        setShowDomainInput(true);
        setStep(1);
    };

    // const switchDropDown = (appName) => {
    //     setOpenDropDownFor(appName);
    //     setDropdownOpen(prevState => !prevState);
    // };

    const generateRandomString = (length) => {
        const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    const handleDomainTypeChange = () => {
        setTempDomain(prevTempDomain => {
            const updatedTempDomain = !prevTempDomain;
    
            if (updatedTempDomain) {
                let domainExists = true;
                let newDomain = '';
    
                // Keep generating a domain name until it's unique
                while (domainExists) {
                    const tempDomain = `temp-${generateRandomString(8)}.poorva.cloud`;
    
                    // Check if the generated domain already exists in the applications array
                    domainExists = applications.some(app => app.applicationName === tempDomain);
    
                    if (!domainExists) {
                        newDomain = tempDomain;
                    }
                }
    
                // Set the domain name if it's unique
                setDomainName(newDomain);
                setDomainError("");
            } else {
                setDomainName("");
            }
    
            return updatedTempDomain;
        });
    };
    
    const validateDomainName = (e) => {
        const enteredDomain = e.target.value;
      
        // Regular expression to validate domain name:
        // - Starts and ends with letters or numbers
        // - Can contain hyphens and dots, but not at the start or end, or consecutively
        const domainRegex = /^[a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*$/;
      
        // Check if the entered domain already exists in the applications array
        const domainExists = applications.some(app => app.applicationName === enteredDomain);
      
        if (domainExists) {
            // Set the domain error if the domain exists
            setDomainName(enteredDomain);
            setDomainError("The domain name already exists. Please choose a different one.");
        } else if (!domainRegex.test(enteredDomain)) {
            // Set the domain error if the domain is invalid
            setDomainName(enteredDomain);
            setDomainError("Invalid domain name. It can only contain letters, numbers, dots, and hyphens (not consecutively or at the start/end).");
        } else {
            // Set the domain name and clear the error if it's valid
            setDomainName(enteredDomain);
            setDomainError("");  // Clear the error if valid
        }
    };
      

    const handleNeedDatabase = () => {
        setNeedDatabase(!needDatabase);
        setDbNameError("");
        setDbUserError("");
        setDbPasswordError("");
        setDbName("");
        setDbUser("");
        setDbPassword("");
    }

    const validateEmail = (value) => {
        if (value.length === 0) {
            return ""; // Clear error message when input length is 0
        }
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!regex.test(value)) {
            return "Please enter a valid email address.";
        }
        return "";
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value); // Always update the state with the input value
        const errorMessage = validateEmail(value);
        setEmailError(errorMessage); // Conditionally set the error message
    };

    const togglePasswordVisibility = (string) => {
        if (string === "db") {
            setDbPasswordVisible(!dbPasswordVisible);
        };

        if (string === "admin") {
            setAdminPasswordVisible(!adminPasswordVisible);
        };
    };

    const validateInput = (value) => {
        if (value.length === 0) {
            return ""; // Clear error message when input length is 0
        }

        const regex = /^[a-zA-Z0-9_]*$/;
        if (!regex.test(value)) {
            return "Only alphanumeric characters and underscores are allowed.";
        }

        if (value.length < 6 || value.length > 12) {
            return "Input must be between 6 and 12 characters long.";
        }
        return "";
    };

    const handleDbNameChange = (e) => {
        const value = e.target.value;
        setDbName(value); // Always update the state with the input value
        const errorMessage = validateInput(value);
        setDbNameError(errorMessage); // Conditionally set the error message
    };

    const handleDbUserChange = (e) => {
        const value = e.target.value;
        setDbUser(value); // Always update the state with the input value
        const errorMessage = validateInput(value);
        setDbUserError(errorMessage); // Conditionally set the error message
    };

    const handleAdminUserChange = (e) => {
        const value = e.target.value;
        setAdminUser(value); // Always update the state with the input value
        const errorMessage = validateInput(value);
        setAdminUserError(errorMessage); // Conditionally set the error message
    };

    const validatePassword = (value) => {
        const regex = /^[a-zA-Z0-9_!@#$%^&*~(){}[\]_\-+=<>?]{8,24}$/;
        if (value.length === 0) {
            return ""; // Clear error message when input length is 0
        }

        if (!regex.test(value)) {
            return "Password must be 8-24 characters long and can only contain alphanumeric characters and specific special characters.";
        }
        return "";
    };

    const handleDbPasswordChange = (e) => {
        const value = e.target.value;
        setDbPassword(value); // Always update the state with the input value
        const errorMessage = validatePassword(value);
        setDbPasswordError(errorMessage); // Conditionally set the error message
    };

    const handleAdminPasswordChange = (e) => {
        const value = e.target.value;
        setAdminPassword(value); // Always update the state with the input value
        const errorMessage = validatePassword(value);
        setAdminPasswordError(errorMessage); // Conditionally set the error message
    };

    const generateRandomPassword = (string) => {
        const upperCaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const lowerCaseChars = "abcdefghijklmnopqrstuvwxyz";
        const numbers = "0123456789";
        const specialChars = "!@#$%^&*(){}[]~_-+=<>?";

        const getRandomChar = (charSet) => {
            const randomIndex = Math.floor(Math.random() * charSet.length);
            return charSet.charAt(randomIndex);
        };

        let password = '';

        // Ensure at least one uppercase, one lowercase, and one number
        password += getRandomChar(upperCaseChars);
        password += getRandomChar(lowerCaseChars);
        password += getRandomChar(numbers);
        password += getRandomChar(specialChars);

        // Generate the rest of the password
        for (let i = 0; i < 9; i++) {
            const randomCharSet = upperCaseChars + lowerCaseChars + numbers + specialChars;
            password += getRandomChar(randomCharSet);
        }

        // Shuffle the password characters
        password = password.split('').sort(() => Math.random() - 0.5).join('');
        if (string === "db") {
            setDbPassword(password);
            setDbPasswordError("");
        };

        if (string === "admin") {
            setAdminPassword(password);
            setAdminPasswordError("");
        }
    };

    const findPort = () => {
        if (applications.length === 0) {
            return 8081;
        };

        const minPort = 8081;
        const maxPort = 9999;

        for (let port = minPort; port <= maxPort; port++) {
            let portInUse = false;

            for (let app of applications) {
                if (parseInt(app.applicationPort) === port) {
                    portInUse = true;
                    break;
                }
            };

            if (!portInUse) {
                // console.log(`Available port found: ${port}`);
                return port; // You can return the port or set it to a container
            };
        }

        setError("No available ports in the specified range.");
        setTimeout(() => {
            setError("");
            setCreatingApp("");
            handleCancel();
        }, 5000);

        return null; // No port found in the range

    };

    useEffect(() => {
        const getCreateProgress = async () => {
        
            try {
                const response = await axios.get(`${process.env.REACT_APP_APPLICATION_MANAGEMENT_API}/api/createApplication/progress/${appBeingCreated?.applicationName}`,
                    { withCredentials: true }
                );
                // setCreateProgress(response?.data);
                if (response?.data?.status && response?.data?.status === "In progress" && response?.data?.percentage) {
                    setCreateProgress(response?.data?.percentage);
                }

                // console.log("CREATEPROGRESSSTATUS", response?.data?.status);
                // console.log("CREATEPROGRESSPERCENTAGE", response?.data?.percentage);

            } catch (err) {
                // setError("An error occurred while deleting the application, try again later.");
                // setTimeout(() => setError(""), 5000);
            };
        };

        let intervalId;
        // setCreatingApp(appBeingCreated?.applicationName);

        if (appBeingCreated?.applicationName) {
            // Start polling every 2 seconds
            intervalId = setInterval(() => {
                getCreateProgress();
            }, 2000);
        }

        return () => {
            // Clear interval if creatingApp becomes false or on component unmount
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [appBeingCreated?.applicationName]);

    useEffect(() => {
        const getDeleteProgress = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_APPLICATION_MANAGEMENT_API}/api/deleteApplication/progress/${appBeingDeleted?.applicationName}`,
                    { withCredentials: true }
                );
                if (response?.data?.status === "In progress") {
                    setDeleteProgress(response?.data?.percentage);
                }
                // console.log("DELETEPROGRESSPERCENTAGE: ", response?.data?.percentage);
                // console.log("DELETEPROGRESSSTATUS: ", response?.data?.status);

            } catch (err) {
                // setError("An error occurred while deleting the application, try again later.");
                // setTimeout(() => setError(""), 5000);
            };
        };

        let intervalId;
        // setDeletingApp(appBeingDeleted?.applicationName);
        // setLoading(false);

        if (appBeingDeleted?.applicationName) {
            // Start polling every 5 seconds
            intervalId = setInterval(() => {
                getDeleteProgress();
            }, 2000);
        }

        return () => {
            // Clear interval if deletingApp becomes false or on component unmount
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [appBeingDeleted?.applicationName]);

    // IF THE USER LOGGED IN MULTIPLE DEVICES, RESET PROGRESS
    useEffect(() => {
        if(!appBeingCreated) {
            setCreateProgress(0);
        };
    });
    
    useEffect(() => {
        if (!appBeingDeleted) {
            setDeleteProgress(0);
        };
    });
    //////////////////////////////////////////

    ///////////////////////////////////////////////////
    // console.log("1. AVAILABLE PORT: ", findPort())
    // console.log("2. LENGTH: ", applications.length);
    // console.log("3. APPS: ", applications);
    // console.log("-----------------------");

    const handleSubmit = async () => {
        setIsButtonDisabled(true);
        setError(""); // Clear previous errors    
        setCreatingApp(domainName);
        // setLoading(true);

        let hasError = false;

        if (!domainName) {
            setDomainError("Domain name is required.");
            hasError = true;
        };

        if (appType === "") {
            setAppError("Select application type");
            hasError = true;
        };

        if (appType === "basic" && needDatabase) {
            if (dbName.length === 0) {
                setDbNameError("Database name is required");
                hasError = true;
            };
            if (dbUser.length === 0) {
                setDbUserError("Database user is required");
                hasError = true;
            };
            if (dbPassword.length === 0) {
                setDbPasswordError("Database user password is required");
                hasError = true;
            };
        }

        if (appType && appType !== "basic") {
            if (dbName.length === 0) {
                setDbNameError("Database name is required");
                hasError = true;
            };
            if (dbUser.length === 0) {
                setDbUserError("Database user is required");
                hasError = true;
            };
            if (dbPassword.length === 0) {
                setDbPasswordError("Database user password is required");
                hasError = true;
            };
            if (adminUser.length === 0) {
                setAdminUserError("Admin user is required");
                hasError = true;
            };
            if (adminPassword.length === 0) {
                setAdminPasswordError("Admin user password is required");
                hasError = true;
            };
            if (email.length === 0) {
                setEmailError("Email address is required");
                hasError = true;
            };
        }

        if (hasError || domainError || appError || dbNameError || dbUserError || dbPasswordError || adminUserError || adminPasswordError || emailError) {
            setIsButtonDisabled(false);
            setCreatingApp("");
            return;
        };

        const availablePort = findPort();
        if (!availablePort) {
            setError("No available ports in the specified range.");
            setIsButtonDisabled(false);
            setCreatingApp("");
            setCreateProgress(0);
            return;
        }

        const payload = {
            userName: user.userName,
            poorvaID: user.poorvaID,
            serverName: server.serverName,
            ipAddress: server.publicIpAddress,
            tempDomain: tempDomain,
            applicationType: appType,
            applicationName: domainName,
            applicationPort: availablePort,
            applicationDbName: dbName,
            applicationDbUser: dbUser,
            applicationDbUserPassword: dbPassword,
            siteTitle: domainName,
            adminUser: adminUser,
            adminPassword: adminPassword,
            // admin_email: email,
            adminEmail: email,
            sshSftpUser: server.sshSftpUser,
        };

        console.log("PAYLOAD: ", payload);

        setShowDomainInput(false);

        if (tempDomain) {
            const subDomain = domainName.replace('.poorva.cloud', '')

            try {
                const res_dns = await axios.post(`${process.env.REACT_APP_DNS_SSL_MANAGEMENT_API}/auth/addDnsRecord`,
                    {
                        subDomain: subDomain,
                        ipAddress: server.publicIpAddress,
                    },
                    { withCredentials: true }
                );
                // console.log("ADD DNS", res_dns.data);
                // setSuccessMessage(res_dns.data.message);

            } catch (err) {
                setDNSError("An error occurred while making changes to the DNS zone or applying the SSL certificate.");
                setTimeout(() => {
                    setDNSError("");
                    setCreatingApp("");
                }, 2000);
            };
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_APPLICATION_MANAGEMENT_API}/api/createApplication`,
                payload,
                { withCredentials: true }
            );
            // console.log("CREATE APP: ", response.data);
            setSuccessMessage(`Application created successfully!`);
            setTimeout(() => {
                setSuccessMessage("");
                setCreatingApp("");
            }, 2000);

        } catch (err) {
            // console.log("CREATE ERROR: ", err.response.data);
            if (err.response?.data?.includes("Error in application creation process")) {
                setError("An error occurred while creating the application. Please try again.");
                setTimeout(() => {
                    setError("");
                    setCreatingApp("");
                }, 2000);

                // DELETING APP WHEN ERROR OCCURRED
                setDeletingApp(domainName);

                if (tempDomain) {
                    try {
                        await axios.post(`${process.env.REACT_APP_DNS_SSL_MANAGEMENT_API}/auth/deleteDnsRecord`,
                            { applicationName: domainName },
                            { withCredentials: true }
                        );
                    } catch (error) {
                        // setErrorDeleteMessage("Application not deleted- An error occurred while removing the DNS records.");
                    };
                };

                try {
                    await axios.post(`${process.env.REACT_APP_APPLICATION_MANAGEMENT_API}/api/deleteApplicationAndRemoveFromDB`,
                        {
                            applicationName: domainName,
                            serverName: server.serverName,
                            userName: user.userName,
                            poorvaID: user.poorvaID
                        },
                        { withCredentials: true }
                    );
                } catch (error) {
                    // setErrorDeleteMessage("An error occurred while deleting the application.");
                    // setTimeout(() => setErrorDeleteMessage(""), 5000);
                };

                setDeletingApp("");
                setDeleteProgress(0);
            }
        };

        // setCreatingApp("");
        setIsButtonDisabled(false);
        handleCancel();
        setCreateProgress(0);
        // setLoading(false);
    };

    const handleCancel = () => {
        setShowDomainInput(false); // Hide the domain input section
        setTempDomain(false);
        setDomainName(""); // Optionally reset domain name
        setAppType(""); // Optionally reset application type
        setDbName(""); // Optionally reset database name
        setDbUser(""); // Optionally reset database user
        setDbPassword(""); // Optionally reset database password
        setAdminUser("");
        setAdminPassword("");
        setEmail("");
        setAdminUserError("");
        setAdminPasswordError("");
        setEmailError("");
        setAppError("");
        setDomainError("");
        setPhpVersion("");
        // setError(""); // Clear any existing error message
        setDbNameError(""); // Clear database name error
        setDbUserError(""); // Clear database user error
        setDbPasswordError(""); // Clear database password error
        setIsButtonDisabled(false); // Disable the create button
        setStep(0);
        // setContainerPort(null);
    };

    /////////////////////////////////////////////

    const deleteApp = async (applicationName, isTemporary) => {

        if (appName !== applicationName) {
            setDialogError("Application name did not match!");
            setTimeout(() => setDialogError(""), 5000);
            setDisableDeleteButton("");
            return;
        };

        setDeletingApp(applicationName);
        setDisableDeleteButton(applicationName);
        setOpenDeleteDialog("");
        // setLoading(true);

        if (isTemporary) {
            try {
                await axios.post(`${process.env.REACT_APP_DNS_SSL_MANAGEMENT_API}/auth/deleteDnsRecord`,
                    { applicationName: applicationName },
                    { withCredentials: true }
                );
            } catch (error) {
                setErrorDeleteMessage("Application not deleted- An error occurred while removing the DNS records.");
                setTimeout(() => {
                    setErrorDeleteMessage("");
                    setDeletingApp("");
                }, 2000);
            };
        };

        try {
            await axios.post(`${process.env.REACT_APP_APPLICATION_MANAGEMENT_API}/api/deleteApplication`,
                {
                    applicationName: applicationName,
                    serverName: server.serverName,
                    userName: user.userName,
                    poorvaID: user.poorvaID
                },
                { withCredentials: true }
            );

            setSuccessDeleteMessage(`Application deleted successfully.`);
            setTimeout(() => {
                setSuccessDeleteMessage("")
            }, 2000);

        } catch (error) {
            setErrorDeleteMessage("An error occurred while deleting the application.");
            setTimeout(() => setErrorDeleteMessage(""), 5000);
        };

        setDisableDeleteButton("");
        setAppName("");
        setDeleteProgress(0);
        setDeletingApp("");
        // setLoading(false);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    // Filtered applications based on search query
    const filteredApps = applications.filter(app =>
        (app.applicationName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        app.applicationType.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    const totalPages = Math.ceil(filteredApps.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to first page when searching
    };

    // Calculate the applications to display on the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const selectedApps = filteredApps.slice(startIndex, startIndex + itemsPerPage);
    //////////////////////////////////////////

    // console.log("DBNAMEERROR: ", dbNameError);
    // console.log("DBUSERERROR: ", dbUserError);
    // console.log("DBPASSERROR: ", dbPasswordError);
    // console.log("ADMINERROR: ", adminUserError);
    // console.log("ADMINPASSERROR: ", adminPasswordError);
    // console.log("EMAILERROR: ", emailError);
    // console.log("STEP: ", step);
    // console.log("CREATEPROGRESS: ", createProgress);

    return (
        <>
            <div>
                <div className="flex flex-wrap mb-10 gap-2 items-center text-xs font-bold">
                    <Link to="/dashboard" className="cursor-pointer hover:text-custom-green">
                        Dashboard
                    </Link>
                    <i className="fa-solid fa-chevron-right"></i>
                    <Link to="/dashboard/products" className="cursor-pointer hover:text-custom-green">
                        Products
                    </Link>
                    <i className=" fa-solid fa-chevron-right"></i>
                    <p className="text-custom-green">
                        Application Manager
                    </p>
                </div>

                <div>
                    <h1 className="text-xl uppercase font-bold">Application Manager</h1>
                    <p className="font-light">Manage your applications- their domains, software versions, etc.</p>
                </div>

                {!showDomainInput && (
                    <button className="mt-8 elastic-button1 px-4"
                        onClick={handleCreateApplicationClick}
                        disabled={!!creatingApp || !!deletingApp || appBeingCreated?.applicationName || appBeingDeleted?.applicationName || error}
                    >
                        Create Application
                    </button>
                )}

                {showDomainInput && (
                    <div className="mt-8">
                        <h1 className="mb-4 text-md font-bold uppercase">Create A New Application</h1>

                        {step === 1 &&
                            <div className="mt-2">
                                <label className="block text-sm font-medium text-gray-700">Application Type</label>
                                <select
                                    value={appType}
                                    onChange={(e) => {
                                        setAppType(e.target.value)
                                        setAppError("");
                                    }}
                                    className="mt-1 md:w-[50%] w-full border-2 border-custom-green bg-black text-white text-sm rounded-md px-1 py-[5.5px]"
                                >
                                    <option value="" style={{ opacity: 0.5 }}>Select application type</option>
                                    <option value="basic">Basic HTML/PHP</option>
                                    <option value="wordpress">WordPress</option>
                                    <option value="magento">Magento</option>
                                    <option value="laravel">Laravel</option>
                                    <option value="opencart">Opencart</option>
                                    <option value="drupal">Drupal</option>
                                    <option value="joomla">Joomla</option>
                                </select>
                                {appError && <div className="text-red-700 text-xs font-bold mt-1">{appError}</div>}

                                <div className="md:w-[50%] w-full mt-8 flex justify-between gap-4">
                                    <div className="flex gap-2">
                                        <button type="submit" disabled className="button-transparent border-2 border-black">Previous</button>
                                        <button type="submit" disabled={appType === ""} onClick={() => setStep(2)} className="button-transparent border-2 border-black">Next</button>
                                    </div>
                                    <button className="button2" onClick={handleCancel}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        }

                        {step === 2 &&
                            <div className="mt-2">
                                <p className="block text-sm font-medium text-gray-700">Domain Name</p>
                                <div className="flex items-center gap-1 mt-1 text-xs">
                                    {/* <span className={`${!tempDomain ? 'text-custom-green font-bold' : ''}`}>Own a domain</span> */}
                                    <i onClick={handleDomainTypeChange}
                                        className={`text-2xl fa-solid ${tempDomain ? 'fa-toggle-on text-custom-green' : 'fa-toggle-off'}`}
                                        role="button"
                                        aria-label="Toggle domain"
                                        tabIndex={0} // Optional: Allows keyboard focus on the icon
                                    >
                                    </i>
                                    <span className={`${tempDomain ? 'text-custom-green font-bold' : ''}`}>Temporary domain</span>
                                </div>
                                {!tempDomain && (
                                    <input
                                        type="text"
                                        placeholder="Enter your domain name"
                                        value={domainName}
                                        onChange={validateDomainName}
                                        className="mt-2 md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                    />
                                )}
                                {tempDomain && (
                                    <input
                                        type="text"
                                        value={domainName}
                                        readOnly
                                        className="mt-2 md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                    />
                                )}
                                {domainError && <div className="text-red-700 text-xs font-bold">{domainError}</div>}

                                <div className="md:w-[50%] w-full mt-8 flex justify-between gap-4">
                                    <div className="flex gap-2">
                                        <button type="submit" onClick={() => setStep(1)} className="button-transparent border-2 border-black">Previous</button>
                                        <button type="submit" disabled={!domainName || domainError} onClick={() => setStep(3)} className="button-transparent border-2 border-black">Next</button>
                                    </div>
                                    <button className="button2" onClick={handleCancel}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        }

                        {(appType === "basic" && step === 3) &&
                            <div className="mt-2">

                                <div className="">
                                    <label className="block text-sm font-medium text-gray-700">Select PHP Version (Optional)</label>
                                    <select
                                        value={phpVersion}
                                        onChange={(e) => setPhpVersion(e.target.value)}
                                        className="md:w-[50%] w-full border-2 border-custom-green bg-black text-white text-sm rounded-md px-1 py-[5.5px]"
                                    >
                                        <option value="" style={{ opacity: 0.5 }}>Select PHP Version</option>
                                        <option value="php74">PHP 7.4</option>
                                        <option value="php80">PHP 8.0</option>
                                        <option value="php81">PHP 8.1</option>
                                        <option value="php82">PHP 8.2</option>
                                        <option value="php83">PHP 8.3</option>
                                    </select>
                                </div>

                                <div className="mt-2">
                                    <label className="text-xs sm:text-sm flex items-center">
                                        <input
                                            type="checkbox"
                                            value=""
                                            checked={needDatabase}
                                            onChange={handleNeedDatabase}
                                            className="mr-1"
                                        />
                                        Create a database
                                    </label>
                                </div>

                                {needDatabase &&
                                    <div className="pl-4 mt-2">
                                        <label className="block text-sm font-medium text-gray-700">Database Name</label>
                                        <input
                                            type="text"
                                            placeholder="Enter database name"
                                            value={dbName}
                                            onChange={handleDbNameChange}
                                            className="md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                        />
                                        {dbNameError && <div className="text-red-700 text-xs font-bold">{dbNameError}</div>}

                                        <label className="block text-sm font-medium text-gray-700 mt-2">Database User</label>
                                        <input
                                            type="text"
                                            placeholder="Enter database user"
                                            value={dbUser}
                                            onChange={handleDbUserChange}
                                            className="md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                        />
                                        {dbUserError && <div className="text-red-700 text-xs font-bold">{dbUserError}</div>}

                                        <label className="block text-sm font-medium text-gray-700 mt-2">Database Password</label>
                                        <div className="flex items-center">
                                            <input
                                                type={dbPasswordVisible ? "text" : "password"}
                                                placeholder="Enter database password"
                                                value={dbPassword}
                                                onChange={handleDbPasswordChange}
                                                className="md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                            />
                                            <i onClick={() => togglePasswordVisibility("db")} className={`-ml-8 z-20 cursor-pointer fa-solid text-white ${dbPasswordVisible ? "fa-eye-slash" : "fa-eye"}`}></i>
                                        </div>
                                        {dbPasswordError && <p className="text-red-700 text-xs font-bold mt-1">{dbPasswordError}</p>}

                                        <button
                                            type="submit"
                                            onClick={() => generateRandomPassword("db")}
                                            className="mt-2 px-2 py-1 border border-black rounded hover:shadow-custom3 text-sm border-opacity-50 transition duration-150 ease-in-out"
                                        >
                                            <i className="fa-solid fa-rotate"></i> Generate Password
                                        </button>
                                    </div>
                                }

                                <div className="md:w-[50%] w-full mt-8 flex justify-between gap-4">
                                    <div className="flex gap-2">
                                        <button type="submit" onClick={() => setStep(2)} className="button-transparent border-2 border-black">Previous</button>
                                        <button type="submit" disabled={needDatabase && ((!dbName || dbNameError) || (!dbUser || dbUserError) || (!dbPassword || dbPasswordError))} onClick={() => setStep(4)} className="button-transparent border-2 border-black">Next</button>
                                    </div>
                                    <button className="button2" onClick={handleCancel}>
                                        Cancel
                                    </button>
                                </div>

                            </div>
                        }

                        {(appType !== "basic" && step === 3) &&
                            <>
                                <div className="mt-2">
                                    <label className="block text-sm font-medium text-gray-700">Database Name</label>
                                    <input
                                        type="text"
                                        placeholder="Enter database name"
                                        value={dbName}
                                        onChange={handleDbNameChange}
                                        className="md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                    />
                                    {dbNameError && <div className="text-red-700 text-xs font-bold">{dbNameError}</div>}

                                    <label className="block text-sm font-medium text-gray-700 mt-2">Database User</label>
                                    <input
                                        type="text"
                                        placeholder="Enter database user"
                                        value={dbUser}
                                        onChange={handleDbUserChange}
                                        className="md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                    />
                                    {dbUserError && <div className="text-red-700 text-xs font-bold">{dbUserError}</div>}

                                    <label className="block text-sm font-medium text-gray-700 mt-2">Database Password</label>
                                    <div className="flex items-center">
                                        <input
                                            type={dbPasswordVisible ? "text" : "password"}
                                            placeholder="Enter database password"
                                            value={dbPassword}
                                            onChange={handleDbPasswordChange}
                                            className="md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                        />
                                        <i onClick={() => togglePasswordVisibility("db")} className={`-ml-8 z-20 cursor-pointer fa-solid text-white ${dbPasswordVisible ? "fa-eye-slash" : "fa-eye"}`}></i>
                                    </div>
                                    {dbPasswordError && <p className="text-red-700 text-xs font-bold mt-1">{dbPasswordError}</p>}

                                    <button
                                        type="submit"
                                        onClick={() => generateRandomPassword("db")}
                                        className="mt-2 px-2 py-1 border border-black rounded hover:shadow-custom3 text-sm border-opacity-50 transition duration-150 ease-in-out"
                                    >
                                        <i className="fa-solid fa-rotate"></i> Generate Password
                                    </button>
                                </div>

                                <div className="md:w-[50%] w-full mt-8 flex justify-between gap-4">
                                    <div className="flex gap-2">
                                        <button type="submit" onClick={() => setStep(2)} className="button-transparent border-2 border-black">Previous</button>
                                        <button type="submit" disabled={(!dbName || dbNameError) || (!dbUser || dbUserError) || (!dbPassword || dbPasswordError)} onClick={() => setStep(4)} className="button-transparent border-2 border-black">Next</button>
                                    </div>
                                    <button className="button2" onClick={handleCancel}>
                                        Cancel
                                    </button>
                                </div>
                            </>
                        }

                        {(appType !== "basic" && step === 4) &&
                            <>
                                <div className="mt-2">
                                    <label className="block text-sm font-medium text-gray-700 mt-2">Admin User</label>
                                    <div className="flex items-center">
                                        <input
                                            placeholder="Enter admin username"
                                            value={adminUser}
                                            onChange={handleAdminUserChange}
                                            className="md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                        />
                                    </div>
                                    {adminUserError && <div className="text-red-700 text-xs font-bold">{adminUserError}</div>}

                                    <label className="block text-sm font-medium text-gray-700 mt-2">Admin Password</label>
                                    <div className="flex items-center">
                                        <input
                                            type={adminPasswordVisible ? "text" : "password"}
                                            placeholder="Enter admin password"
                                            value={adminPassword}
                                            onChange={handleAdminPasswordChange}
                                            className="md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                        />
                                        <i onClick={() => togglePasswordVisibility("admin")} className={`-ml-8 z-20 cursor-pointer fa-solid text-white ${adminPasswordVisible ? "fa-eye-slash" : "fa-eye"}`}></i>
                                    </div>
                                    {adminPasswordError && <p className="text-red-700 text-xs font-bold mt-1">{adminPasswordError}</p>}

                                    <button
                                        type="submit"
                                        onClick={() => generateRandomPassword("admin")}
                                        className="mt-2 px-2 py-1 border border-black rounded hover:shadow-custom3 text-sm border-opacity-50 transition duration-150 ease-in-out"
                                    >
                                        <i className="fa-solid fa-rotate"></i> Generate Password
                                    </button>

                                    <div className="mt-2">
                                        <label className="block text-sm font-medium text-gray-700">Email Address</label>
                                        <input
                                            type="email"
                                            placeholder="Enter your email address"
                                            value={email}
                                            onChange={handleEmailChange}
                                            className="md:w-[50%] w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                        />
                                        {emailError && <div className="text-red-700 text-xs font-bold">{emailError}</div>}
                                    </div>
                                </div>
                                <div className="md:w-[50%] w-full mt-8 flex justify-between gap-4">
                                    <div className="flex gap-2">
                                        <button type="submit" onClick={() => setStep(3)} className="button-transparent border-2 border-black">Previous</button>
                                        <button type="submit" disabled={(!adminUser || adminUserError) || (!adminPassword || adminPasswordError) || (!email || emailError)} onClick={() => setStep(5)} className="button-transparent border-2 border-black">Next</button>
                                    </div>
                                    <button className="button2" onClick={handleCancel}>
                                        Cancel
                                    </button>
                                </div>
                            </>
                        }

                        {((appType === "basic" && step === 4) || (appType !== "basic" && step === 5)) &&
                            <div className="w-full md:w-[50%] mt-4 flex flex-col">
                                <div className="flex flex-col gap-2 text-sm border border-black rounded-lg border-opacity-50 p-4">
                                    <h4 className="font-bold">Application Details:</h4>
                                    <div className="flex flex-col">
                                        <div>App Type: <span className="text-custom-green font-bold capitalize">{appType === "wordpress" ? "WordPress" : `${appType}`}</span></div>
                                        <div>Domain Name: <span className="text-custom-green font-bold">{domainName}</span></div>
                                    </div>
                                    {((appType === "basic" && needDatabase) || appType !== "basic") &&
                                        <div className="flex flex-col">
                                            <p>Database Name: <span className="text-custom-green font-bold">{dbName}</span></p>
                                            <p>Database User: <span className="text-custom-green font-bold">{dbUser}</span></p>
                                            <p>Database Pass: <span className="text-custom-green font-bold">{dbPassword}</span></p>
                                        </div>
                                    }
                                    {adminUser && adminPassword &&
                                        <div className="flex flex-col">
                                            <p>Admin User: <span className="text-custom-green font-bold">{adminUser}</span></p>
                                            <p>Admin Pass: <span className="text-custom-green font-bold">{adminPassword}</span></p>
                                        </div>
                                    }

                                    {email && <p>Admin Email: <span className="text-custom-green font-bold">{email}</span></p>}
                                    {phpVersion && <p>PHP Version: <span className="text-custom-green font-bold">{phpVersion}</span></p>}
                                </div>
                                <div className="mt-4 w-full flex justify-between items-center">
                                    <button type="submit" onClick={() => setStep(3)} className="button-transparent border-2 border-black">Previous</button>
                                    <div className="flex justify-start items-center gap-2">
                                        <button
                                            className="elastic-button1 w-32"
                                            onClick={handleSubmit}
                                            disabled={isButtonDisabled}
                                        >
                                            {!creatingApp ? (
                                                <p>Create</p>
                                            ) : (
                                                <div className="spinner2"></div>
                                            )}

                                        </button>
                                        <button className="button2" onClick={handleCancel}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                )}

                {!showDomainInput &&
                    <div className="relative w-auto py-5 sm:m-5 flex flex-col gap-4 overflow-x-auto">
                        <div className="w-[300%] md:w-auto flex justify-between items-center">
                            <h1 className="text-md font-bold uppercase">Apps' list</h1>
                            <p className="text-sm">
                                {((currentPage - 1) * itemsPerPage) + 1 > applications.length
                                    ? applications.length
                                    : ((currentPage - 1) * itemsPerPage) + 1
                                }-
                                {(currentPage * itemsPerPage) > applications.length 
                                    ? applications.length 
                                    : (currentPage * itemsPerPage)
                                } {" "}
                                of {applications.length} records.
                            </p>
                        </div>
                        <div className="w-[300%] md:w-full flex items-center text-left">
                            <input
                                type="text"
                                placeholder="Search by application name, application type..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-lg focus:outline-none focus:border-black focus:ring-black focus:ring-1"
                                disabled={applications.length === 0}
                            />
                            <i className={`${searchQuery ? "hover:opacity-80 cursor-pointer" : ""} fa-solid fa-x -ml-8 opacity-50`} title={`${searchQuery ? "clear" : ""}`} disabled={!searchQuery} onClick={() => setSearchQuery("")}></i>
                        </div>

                        {/* {loading &&
                            <div className='z-30 absolute top-28 h-full w-full bg-white bg-opacity-95 overflow-y-auto flex justify-center items-start font-bold'>
                                <div className="flex justify-center items-center gap-1 mt-10">
                                    <div className='spinner'></div>
                                    <p>Loading...</p>
                                </div>
                            </div>
                        } */}

                        {applications.length === 0 ? (
                            <>
                                {!creatingApp ? (
                                    <div className="w-[300%] md:w-full flex justify-center items-center py-4 opacity-30">
                                        <p className="text-lg font-bold">No apps are currently installed</p>
                                    </div>
                                ) : (
                                    <div className="bg-gray-100 p-4 rounded-lg drop-shadow flex justify-between items-center gap-4 md:gap-2">
                                        <div className="flex flex-1 flex-col flex-wrap justify-start">
                                            {/* Truncate application name and show full name on hover */}
                                            <p
                                                title={creatingApp}
                                                className="text-base font-bold truncate max-w-[32ch]"
                                            >
                                                {creatingApp.length > 24 ? `${creatingApp.slice(0, 24)}...` : creatingApp}{" "}<i className="text-black fa-solid fa-up-right-from-square"></i>
                                            </p>
                                            <p className="text-sm capitalize">
                                                {appType === "wordpress" ? "WordPress" : appType}
                                            </p>
                                        </div>
                                        
                                        <div className="flex flex-1 justify-center items-center gap-1 text-sm font-bold">
                                            <div className="spinner2-sm"></div>
                                            Loading...
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                {selectedApps.length > 0 ? (
                                    <div className="w-[300%] md:w-full pb-2 flex flex-col gap-4">
                                        {(creatingApp && !appBeingCreated?.applicationName) &&
                                            <div className="bg-gray-100 p-4 rounded-lg drop-shadow flex justify-between items-center gap-4 md:gap-2">
                                                <div className="flex flex-1 flex-col flex-wrap justify-start">
                                                    {/* Truncate application name and show full name on hover */}
                                                    <p
                                                        title={creatingApp}
                                                        className="text-base font-bold truncate max-w-[32ch]"
                                                    >
                                                        {creatingApp.length > 24 ? `${creatingApp.slice(0, 24)}...` : creatingApp}{" "}<i className="text-black fa-solid fa-up-right-from-square"></i>
                                                    </p>
                                                    <p className="text-sm capitalize">
                                                        {appType === "wordpress" ? "WordPress" : appType}
                                                    </p>
                                                </div>
                                                
                                                <div className="flex flex-1 justify-center items-center gap-1 text-sm font-bold">
                                                    <div className="spinner2-sm"></div>
                                                    Loading...
                                                </div>
                                            </div>
                                        }

                                        {selectedApps
                                        .sort((a, b) => {
                                            const statusOrder = {
                                              deleting: 1,  // "deleting" comes first
                                              errorOnCreation: 1,   // "errorOnCreation" also comes first
                                              creating: 2,  // "creating" comes second
                                              other: 3      // all other statuses come last
                                            };
                                        
                                            // Determine the order value for application A
                                            const aStatusOrder = statusOrder[a.applicationStatus] || statusOrder.other;
                                        
                                            // Determine the order value for application B
                                            const bStatusOrder = statusOrder[b.applicationStatus] || statusOrder.other;
                                        
                                            // Sort by the defined order values (ascending)
                                            return aStatusOrder - bStatusOrder;
                                          })
                                        .map(app => (
                                            <div key={app.applicationName}>
                                                <div className="w-full" key={app.applicationName}>
                                                    <div className="bg-gray-100 p-4 rounded-lg drop-shadow flex justify-between items-center gap-4 md:gap-2">
                                                        <div className="flex flex-1 flex-col flex-wrap justify-start">
                                                            {/* Truncate application name and show full name on hover */}
                                                            <a
                                                                href={`http://${app.applicationName}`}
                                                                // className="text-base font-bold truncate max-w-[20ch] hover:overflow-visible"
                                                                className="text-base font-bold truncate max-w-[32ch]"
                                                                title={app.applicationName}  // Shows full name on hover
                                                                target="_blank" // Opens the link in a new tab.
                                                                rel="noopener noreferrer" // Improves security and performance when opening links in a new tab by preventing access to the window.opener object (recommended practice for external links)
                                                            >
                                                                {app.applicationName.length > 24 ? `${app.applicationName.slice(0, 24)}...` : app.applicationName}{" "}<i className="text-black fa-solid fa-up-right-from-square"></i>
                                                            </a>
                                                            <p className="text-sm capitalize">
                                                                {app.applicationType === "wordpress" ? "WordPress" : app.applicationType}
                                                            </p>
                                                        </div>

                                                        <div className="flex flex-1 md:justify-center justify-end">
                                                            {(deletingApp === app.applicationName || app.applicationStatus === "deleting") ? (
                                                                <div>
                                                                    {successDeleteMessage &&
                                                                        <div className="text-green-700 text-xs font-bold">{successDeleteMessage}</div>
                                                                    }
                                                                    {errorDeleteMessage &&
                                                                        <div className="text-red-700 text-xs font-bold">{errorDeleteMessage}</div>
                                                                    }
                                                                    {(!successDeleteMessage && !errorDeleteMessage) &&
                                                                        <div className="flex items-start flex-col">
                                                                            <div className="flex flex-1 justify-end items-center gap-2">
                                                                                <div className="spinner2-custom-red"></div>
                                                                                <div className="text-sm font-bold flex gap-1">{deleteProgress === 0 && !successDeleteMessage ? <div className="flex justify-center items-center gap-1 text-sm font-bold"><div className="spinner2-sm"></div>Loading...</div> : `Deleting ${deleteProgress}%`}</div>
                                                                            </div>
                                                                            {deleteProgress > 0 &&
                                                                                <p className="text-xs">Deleting application might take up to 3 minutes</p>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            ) : (app.applicationStatus === "errorOnCreation") ? (
                                                                <>
                                                                    {error ? (
                                                                        <div className="text-red-700 text-xs font-bold">{error}</div>
                                                                    ) : (
                                                                        <div className="flex items-start flex-col">
                                                                                <div className="flex flex-1 justify-end items-center gap-2">
                                                                                <div className="spinner2-custom-red"></div>
                                                                                <div className="text-sm font-bold flex gap-1 text-red-700">Error in creation. Reverting...</div>
                                                                            </div>
                                                                            <p className="text-xs">This might take up to 2 minutes</p>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ) : (app.applicationStatus === "creating") ? (
                                                                <div>
                                                                    {dnsError &&
                                                                        <div className="text-red-700 text-xs font-bold">{dnsError}</div>
                                                                    }
                                                                    {error &&
                                                                        <div className="text-red-700 text-xs font-bold">{error}</div>
                                                                    }
                                                                    {successMessage &&
                                                                        <div className="text-green-700 text-xs font-bold">{successMessage}</div>
                                                                    }
                                                                    {((!dnsError && !error && !successMessage)) &&
                                                                        <div className="flex items-start flex-col">
                                                                            <div className="flex flex-1 justify-end items-center gap-2">
                                                                                <div className="spinner2-custom-green"></div>
                                                                                <div className="text-sm font-bold flex gap-1">{createProgress === 0  && !successMessage ? <div className="flex justify-center items-center gap-1 text-sm font-bold"><div className="spinner2-sm"></div>Loading...</div> : `Creating ${createProgress}%`}</div>
                                                                            </div>
                                                                            {createProgress > 0 &&
                                                                                <p className="text-xs">Creating application might take up to 5 minutes</p>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            ) : (
                                                                <div className="flex flex-col justify-start items-start gap-2">
                                                                    <div className="flex items-center gap-2 text-sm">
                                                                        {app.applicationStatus === "running" && <div className="w-5 h-5 bg-green-600 shadow rounded-full"></div>}
                                                                        {app.applicationStatus !== "running" && <div className="w-5 h-5 bg-red-600 shadow rounded-full"></div>}
                                                                        <p className="block capitalize">{app.applicationStatus}</p>
                                                                    </div>
                                                                    <p className="text-xs">Created: <span className="font-bold">{app.applicationCreationDate}</span></p>
                                                                </div>
                                                            )}
                                                        </div>

                                                        {(deletingApp !== app.applicationName && creatingApp !== app.applicationName) && (app.applicationStatus !== "deleting" && app.applicationStatus !== "creating" && app.applicationStatus !== "errorOnCreation") &&
                                                            <div className="flex flex-1 justify-end items-center gap-4 z-50">
                                                                <div className="flex justify-end flex-1">
                                                                    <div className="flex justify-start items-center gap-2">
                                                                        <Link
                                                                            to={`/dashboard/manageserver/${collectionName}/app/${app.applicationName}?appDetails`}
                                                                            className="cursor-pointer hover:text-white hover:bg-black border border-black border-opacity-50 md:w-10 md:h-10 w-12 h-12 rounded-lg flex justify-center items-center transition duration-150 ease-in-out"
                                                                            title="Manage"
                                                                        >
                                                                            <i className="fa-solid fa-screwdriver-wrench"></i>
                                                                        </Link>
                                                                        <button type="button" className={`${creatingApp || deletingApp || appBeingCreated?.applicationName || appBeingDeleted?.applicationName ? "opacity-50 cursor-not-allowed" : "cursor-pointer hover:text-white hover:bg-red-700"} text-red-700 border border-red-700 border-opacity-50 md:w-10 md:h-10 w-12 h-12 rounded-lg flex justify-center items-center transition duration-150 ease-in-out`}
                                                                            title="Delete"
                                                                            onClick={() => { setOpenDeleteDialog(app.applicationName) }}
                                                                            disabled={creatingApp || deletingApp  || appBeingCreated?.applicationName || appBeingDeleted?.applicationName}
                                                                        >
                                                                            <i className="fa-solid fa-trash-can"></i>
                                                                        </button>
                                                                        <button type="button" className={`${creatingApp || deletingApp || appBeingCreated?.applicationName || appBeingDeleted?.applicationName ? "opacity-50 cursor-not-allowed" : "cursor-pointer hover:text-white hover:bg-custom-green"} text-custom-green border border-custom-green border-opacity-50 md:w-10 md:h-10 w-12 h-12 rounded-lg flex justify-center items-center transition duration-150 ease-in-out`}
                                                                            title="Clone"
                                                                            disabled={creatingApp || deletingApp  || appBeingCreated?.applicationName || appBeingDeleted?.applicationName}
                                                                        >
                                                                            <i className="fa-solid fa-clone"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    {openDeleteDialog === app.applicationName &&
                                                        <div className="fixed top-0 left-0 w-[100vw] h-[100vh] z-50 bg-black bg-opacity-70 flex justify-center items-center">
                                                            <div className="w-full md:w-[40%] mt-4 flex flex-col items-center justify-center text-left mx-4 md:m-0 p-4 text-sm bg-white rounded-lg shadow-custom3">
                                                                <h3 className="text-xl text-red-700 font-bold uppercase">DELETE WEBSITE:</h3>
                                                                <p className="text-left mt-10">
                                                                    <span className="font-bold text-red-700">WARNING: </span>
                                                                    This will delete the entire website with it's files and database if it has any.
                                                                </p>
                                                                <p className="mt-5 mb-1">To proceed, type the application name (<span className="italic font-bold text-base">{app.applicationName}</span>) in the required field.</p>
                                                                <input
                                                                    className="w-full placeholder:italic placeholder:opacity-50 text-white placeholder:text-white bg-black border-2 border-custom-green rounded-md py-2 px-3 focus:outline-none focus:border-black focus:ring-black focus:ring-1 sm:text-sm"
                                                                    type="text"
                                                                    name="appName"
                                                                    required
                                                                    value={appName}
                                                                    onChange={(e) => setAppName(e.target.value)}
                                                                />

                                                                {dialogError &&
                                                                    <div className="text-red-700 text-xs font-bold mt-1 text-left flex w-full">{dialogError}</div>
                                                                }

                                                                <div className="w-full mt-2 flex justify-end items-center gap-2">
                                                                    <button
                                                                        type="submit"
                                                                        onClick={() => {
                                                                            setOpenDeleteDialog("");
                                                                            setAppName("");
                                                                            setDialogError("");
                                                                        }}
                                                                        disabled={disableDeleteButton === app.applicationName}
                                                                        className="button-transparent border-2 border-black rounded-md text-black"
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                    <button
                                                                        type="submit"
                                                                        className="button-red border-2 border-red-700"
                                                                        onClick={() => { deleteApp(app.applicationName, app.tempDomain) }}
                                                                        disabled={disableDeleteButton === app.applicationName}
                                                                    >
                                                                        {disableDeleteButton === app.applicationName ? <div className="spinner-sm"></div> : "Delete"}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (searchQuery &&
                                    <p className="text-red-700 text-xs font-bold">No applications found for your search.</p>
                                )
                                }
                            </>
                        )}

                        <div className="flex justify-between mt-10 w-[300%] md:w-full">
                            <button
                                onClick={handlePreviousPage}
                                disabled={currentPage === 1}
                                className={`${currentPage === 1 ? "opacity-40" : "hover:text-custom-green"} flex items-center gap-1`}
                            >
                                <i className="fa-solid fa-arrow-left-long"></i>
                                <p>Previous</p>
                            </button>
                            <div className="flex justify-center items-center gap-4">
                                <div>
                                    {Array.from({ length: totalPages }, (_, index) => {
                                        const pageNumber = index + 1;
                                        return (
                                            <button
                                                key={pageNumber}
                                                onClick={() => handlePageClick(pageNumber)}
                                                className={`${currentPage === pageNumber ? 'text-custom-green font-bold' : 'text-black'} pr-1`}
                                            >
                                                {pageNumber}
                                            </button>
                                        );
                                    })}
                                </div>
                                
                                <div className="text-sm font-light flex justify-center items-center gap-1">
                                    <p>Per Page: </p>
                                    <input
                                        className="w-10 border border-black border-opacity-30 rounded py-[1px] px-1 no-arrows focus:outline-none focus:border-black focus:ring-black focus:ring-1"
                                        defaultValue={itemsPerPage} // Use defaultValue to let the user modify it
                                        type="number"
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                const value = e.target.value;
                                                // Check if the input is a valid integer
                                                if (value === '' || /^\d+$/.test(value)) {
                                                    // Update state only if the input is a valid number when Enter is pressed
                                                    setItemsPerPage(parseInt(value, 10) || 0); // Use parseInt to convert to a number
                                                }
                                            }
                                        }}
                                    />
                                </div>

                                <button className={`${totalPages <= 1 ? "cursor-not-allowed" : "hover:border-opacity-100"} px-1 py-[1px] text-sm font-light border border-black border-opacity-30 rounded`}
                                    onClick={() => {
                                        setItemsPerPage(applications.length);
                                        setCurrentPage(1);
                                    }}
                                    disabled={totalPages <= 1}
                                >
                                    All
                                </button>
                            </div>

                            <button
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages || totalPages <= 1}
                                className={`${currentPage === totalPages || totalPages <= 1 ? "opacity-40" : "hover:text-custom-green"} flex items-center gap-1`}
                            >
                                <p>Next</p>
                                <i className="fa-solid fa-arrow-right-long"></i>
                            </button>
                        </div>
                    </div>
                }

            </div>
        </>
    );
}

export default CreateApp;
